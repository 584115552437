import 'jquery'
import 'bootstrap'
import 'bootstrap-select'
import 'wavesurfer/swfobject.js'
import WaveSurfer from 'wavesurfer/wave.js';
//import 'wavesurfer/wavesurfer.swf.js'
import 'magnific-popup/dist/jquery.magnific-popup.js'
import 'evenColumns/evenColumns.js'
import Isotope from 'isotope-layout/js/isotope.js'
import jQueryBridget from 'jquery-bridget'
import 'slick-carousel/slick/slick.js'
import 'select2/dist/js/select2.full.js';
import jsTimezoneDetect from 'jstimezonedetect/dist/jstz.js';

require("@rails/ujs").start()
require("custom");

window.jQuery = jQuery
window.$ = $
window.Rails = Rails;
window.jstz = jsTimezoneDetect;
window.WaveSurfer = WaveSurfer;

jQueryBridget( 'isotope', Isotope, $ );

/**
 * --------------------------------------------------------------------------
 * SCRIPTS
 * --------------------------------------------------------------------------
 *
 * Here we define config of APP
 */

'use strict';

var APP = {
	utilities: {},
	config: {}
};

/**
 * --------------------------------------------------------------------------
 * CONSTANTS
 * --------------------------------------------------------------------------
 *
 * Load tested earlier, universal modules
 */

/**
 * --------------------------------------------------------------------------
 * PubSub - Publish Subscibe (Mediator)
 * --------------------------------------------------------------------------
 */

APP.utilities.pubsub = {
	pubsub: {},
	subscribe: function (pubName, fn) {
		this.pubsub[pubName] = this.pubsub[pubName] || [];
		this.pubsub[pubName].push(fn);
	},
	unsubscribe: function(pubName, fn) {
		if (this.pubsub[pubName]) {
			for (var i = 0; i < this.pubsub[pubName].length; i++) {
				if (this.pubsub[pubName][i] === fn) {
					this.pubsub[pubName].splice(i, 1);
					break;
				}
			};
		}
	},
	publish: function (pubName, data) {
		if (this.pubsub[pubName]) {
			this.pubsub[pubName].forEach(function(fn) {
				fn(data);
			});
		}
	}
};

/**
 * --------------------------------------------------------------------------
 * Breakpoints
 * --------------------------------------------------------------------------
 */

APP.utilities.breakpoints = (function() {

	// --------------------------------------------------------------------------
	// Get HTML body::before pseudoelement content.
	// It should be include-media variable, eg. '(sm: 576px, md: 768px, lg: 992px, xl: 1200px)'

	var data = window.getComputedStyle(document.body, '::before').getPropertyValue('content').replace(/[\"\'\s]/g, '');

	// Cut the (brackets)
	data = data.slice(1, -1);

	// Split data by comma
	var dataArr = data.split(',');
	dataArr.unshift('zero:0px');

	// --------------------------------------------------------------------------

	function checkBreakpoint() {

		dataArr.forEach(function(val, i) {

			var breakpoint = val.split(':');
			var breakpointName = breakpoint[0];
			var currValue = breakpoint[1].slice(0, -2);

			if (i !== dataArr.length - 1) { var nextValue = dataArr[i+1].split(':')[1].slice(0, -2) - 1; }

			if (i === 0) { var query = window.matchMedia('screen and (max-width: '+ nextValue +'px)'); }
			else if (i === dataArr.length - 1) { var query = window.matchMedia('screen and (min-width: '+ currValue +'px)'); }
			else { var query = window.matchMedia('screen and (min-width: '+ currValue +'px) and (max-width: '+ nextValue +'px)'); }

			query.addListener(change);
			function change() { query.matches ? APP.utilities.pubsub.publish('breakpoint', [breakpointName, currValue]) : null; }
			change();

		});
	}

	// --------------------------------------------------------------------------
	// Return

	return {
		check: checkBreakpoint
	}

})();

/**
 * --------------------------------------------------------------------------
 * Device
 * --------------------------------------------------------------------------
 */

APP.utilities.device = (function() {

	function isPhone() {
		if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return true;
		} else {
			return false;
		}
	}

	function isTablet() {
		if (/iPad/i.test(navigator.userAgent)) {
			return true;
		} else {
			return false;
		}
	}

    function isIE() {
        if(navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > 0){
       /* Microsoft Internet Explorer detected in. */
            return true;
       } else {
            return false;
       }
    }

	return {
		isPhone: isPhone,
		isTablet: isTablet,
        isIE: isIE
	};

}());


/**
 * --------------------------------------------------------------------------
 * MODULES
 * --------------------------------------------------------------------------
 *
 * Load specific to the project modules
 */

/**
 * --------------------------------------------------------------------------
 * VIEW
 * --------------------------------------------------------------------------
 */

APP.utilities.detection = (function() {

	// --------------------------------------------------------------------------
	// Cache DOM

	var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var platform = window.navigator.platform,
        os = null;

    var uagent = navigator.userAgent.toLowerCase();

    var $header = $('.header');

	// --------------------------------------------------------------------------
	// Bind events



	// --------------------------------------------------------------------------
	// Functions


      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        $('.header').addClass('windows-phone');

    }

    if (/android/i.test(userAgent)) {
        $('.header').addClass('android-phone');
        $('body').addClass('android-phone');
        $('.btn').addClass('android-phone');

    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        $('body').addClass('ios-phone');
    }

    if (!os && /Linux/.test(platform)) {
        os = 'Linux';
        $header.addClass('linux');
        $('.box').addClass('linux');
    } else if(!os && /Mac/.test(platform)) {

        if(/firefox/.test(uagent)) {
            $header.addClass('mac-moz');
        } else if(/chrome/.test(uagent)) {
            $header.addClass('mac-chrome');
        }
    }


    if(/safari/.test(uagent) && !/chrome/.test(uagent)) {
        $('body').addClass('safari-fix');
    }

    if(navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > 0){
       /* Microsoft Internet Explorer detected in. */
       $('body').addClass('ie-fix');
    }

    return "unknown";


})();

/**
 * --------------------------------------------------------------------------
 * View
 * --------------------------------------------------------------------------
 */

APP.utilities.video = (function() {

	// --------------------------------------------------------------------------
	// Cache DOM

    var $document = $(document);

	// --------------------------------------------------------------------------
	// Bind events

	// --------------------------------------------------------------------------
	// Functions

    if($('.popup-youtube').length){
        $('.popup-youtube').magnificPopup({
            disableOn: 0,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    }


})();

/**
 * --------------------------------------------------------------------------
 * View
 * --------------------------------------------------------------------------
 */

APP.utilities.slider = (function() {

	// --------------------------------------------------------------------------
	// Cache DOM

    var $trusted = $('.trusted__slider');

	// --------------------------------------------------------------------------
	// Bind events

	// --------------------------------------------------------------------------
	// Functions

    $trusted.slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1000,
        fade: false,
        responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1
          }
        },
        ]
    });

    var $trustedRealestate = $('.trusted__slider--realestate');

  	// --------------------------------------------------------------------------
  	// Bind events

  	// --------------------------------------------------------------------------
  	// Functions

      $trustedRealestate.slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 1000,
          fade: false,
          responsive: [
          {
              breakpoint: 1400,
              settings: {
                  slidesToShow: 5
              }
          },
          {
              breakpoint: 1050,
              settings: {
                slidesToShow: 4
              }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2
              }
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1
            }
          },
          ]
      });
})();

/**
 * --------------------------------------------------------------------------
 * VIEW
 * --------------------------------------------------------------------------
 */

APP.utilities.columns = (function() {

	// --------------------------------------------------------------------------
	// Cache DOM

	var $code = $('#breakpoint');

	// --------------------------------------------------------------------------
	// Bind events

	APP.utilities.pubsub.subscribe('breakpoint', setColumns);

	// --------------------------------------------------------------------------
	// Functions

	function setColumns(value) {
		$('.solutions__topic').evenColumns(
            {
                columns: 100
            },
            {
                maxScreenWidth: 767,
                columns: 8
            }
        );

        $('.detail').evenColumns(
            {
                columns: 100
            },
            {
                maxScreenWidth: 767,
                columns: 8
            }
        );
	}



})();

/**
 * --------------------------------------------------------------------------
 * View
 * --------------------------------------------------------------------------
 */

APP.utilities.video = (function() {

	// --------------------------------------------------------------------------
	// Cache DOM

    var $header = $('.header');
    var className = 'sticky';
    var page = document.querySelector('body').dataset.name;

	// --------------------------------------------------------------------------
	// Bind events
    APP.utilities.pubsub.subscribe('breakpoint', headerSubmenu);

	// --------------------------------------------------------------------------
	// Functions

    function menuSticky() {
        $(window).on('scroll', function(){

            $(window).scrollTop() > 0 ? makeSticky() : makeStatic();
        });
    }

    function makeSticky() { $header.addClass(className).addClass(className + '--' + page + ''); }
    function makeStatic() { $header.removeClass(className).removeClass(className + '--' + page + ''); }

    menuSticky();

    $('.header__trigger').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        var target = $(this).data('target');
        $(this).toggleClass('active');
        $('#' + target).slideToggle();
        $('.header').toggleClass('active');
        //$('body').toggleClass('lock');
    });

    function headerSubmenu(value) {
        if( value[0] !== 'xxl' ) {


            $('.menu_link').off('click');
            $('.menu_link').on('click', function(e){
                e.preventDefault();
                var $submenu = $(this).parent().find('.header__submenu');
                if($submenu.hasClass('active')) {
                    $submenu.removeClass('active').css({'display': 'none'});
                } else {
                    $submenu.addClass('active').css({'display': 'flex'});
                }
            });
        } else {
            $('.header__item--dropdown menu_link').off('click');
            $('.header__submenu').removeClass('active').css({'display': 'none'});
        }
    }




})();

/**
 * --------------------------------------------------------------------------
 * View
 * --------------------------------------------------------------------------
 */

APP.utilities.audio = (function() {

    if($('.api__box--player').length > 0) {
        if(APP.utilities.device.isIE()) {
            var $playButton = $('.code__btn');
            var $playPause = $('.btn--audio');
            var $playTime = $('.player__time');
            var $playVolume = $('.btn--volume');
            var $playRange = $('.player__range');
            var $playVol = $('.player__vol');

            var audio = new Audio('/audios/lead-qual-demo-call.mp3');

            var formatTimer = function (time) {
            return [
                ('0' + Math.floor((time % 3600) / 3600)), // hours
                ('0' + Math.floor((time % 3600) / 60)), // minutes
                ('00' + Math.floor(time % 60)).slice(-2) // seconds
                ].join(':');
            };

            $(document).on('click', '.code__btn', function(e) {
                e.preventDefault();
                e.stopPropagation();
                $('.btn--audio').addClass('playing');
                audio.play();
                //$inner.toggleClass('code__inner--hidden');
            });

            $(document).on('click', '.btn--audio', function(e){
                e.preventDefault();
                e.stopPropagation();
                if($playPause.hasClass('playing')) {
                    audio.pause();
                    $playPause.removeClass('playing');
                } else {
                    audio.play();
                    $playPause.addClass('playing');
                }
            });

            $playVolume.on('click', function(e){
                e.preventDefault();
                $playRange.toggle();
            });

            $playVol.on('change', function(){
                var $thisVol = ($(this).val()) / 100;
                audio.volume = $thisVol;
            });

            audio.oncanplay = function () {
                $playTime.text( formatTimer(audio.currentTime) );
                $playButton.show();
                $playPause.css({'display': 'inline-block'});
                $('.player__chart').show();
                audio.volume = 0.5;
                $playVol.val(50);
            };

            audio.ontimeupdate = function () {
                $playTime.text( formatTimer(audio.currentTime) );
            };



        } else {
            var wavesurfer = WaveSurfer.create({
                container: '#player',
                barWidth: 2,
                barHeight: 1,
                cursorColor: '#779af6',
                cursorWidth: 1,
                progressColor: '#fff'
            });

            var $playButton = $('.code__btn');
            var $playPause = $('.btn--audio');
            var $playTime = $('.player__time');
            var $playVolume = $('.btn--volume');
            var $playRange = $('.player__range');
            var $playVol = $('.player__vol');
            var $inner = $('.code__inner');

            var formatTime = function (time) {
            return [
                ('0' + Math.floor((time % 3600) / 3600)), // hours
                ('0' + Math.floor((time % 3600) / 60)), // minutes
                ('00' + Math.floor(time % 60)).slice(-2) // seconds
                ].join(':');
            };

            // --------------------------------------------------------------------------
            // Bind events
            $(document).on('click', '.code__btn', function(e) {
                e.preventDefault();
                e.stopPropagation();
                wavesurfer.play();
                $('.btn--audio').addClass('playing');
                $('.code__inner').toggle();
                $('.code__btn').hide();
            });

            $(document).on('click', '.btn--audio', function(e){
                e.preventDefault();
                e.stopPropagation();
                $playPause.toggleClass('playing');
                wavesurfer.playPause();

            });

            $playVolume.on('click', function(e){
                e.preventDefault();
                $playRange.toggle();
            });

            $playVol.on('change', function(){
                var $thisVol = ($(this).val()) / 100;
                wavesurfer.setVolume($thisVol);
            });

            // Show current time
            wavesurfer.on('ready', function () {
                $playTime.text( formatTime(wavesurfer.getCurrentTime()) );
                $playButton.show();
                $playPause.css({'display': 'inline-block'});
                wavesurfer.setVolume(0.5);
                $playVol.val(50);
            });

            wavesurfer.on('audioprocess', function () {
                $playTime.text( formatTime(wavesurfer.getCurrentTime()) );
            });

            // --------------------------------------------------------------------------
            // Functions

            wavesurfer.load('/audios/lead-qual-demo-call.mp3');
        }
    }



	// --------------------------------------------------------------------------
	// Cache DOM




})();

 APP.utilities.formValidation = (function() {

	// -----------------------
	// Cache DOM

	var $form = $('form');
	var $inputs = $form.find('input,textarea,select');
	var $submit = $form.find('button.btn');

	// -----------------------
	// Bind events

	$inputs.on('change blur keyup', _validateSingle);
    $submit.on('click', _validateAll);

	// -----------------------
	// Validation functions

	var countCorrect;

	function _validateAll(e) {
        e.preventDefault();
		countCorrect = 0;
		$inputs.each(_validateSingle);

		countCorrect === $inputs.length ?
			sendEmail() : null;
	}

	function _validateSingle(e) {

		var $field = $(this),
			type = $field.attr('type'),
			value = $field.val();


		switch(type) {
                case 'number':
                    value % 1 === 0 && value > 0 && value < 30 ?
                        _markAsCorrect($field) :
                        _markAsIncorrect($field);
                    break;
                case 'email':
                    /^[A-Z0-9._%+\-]+@[A-Z0-9.\-]+\.[A-Z]{2,4}$/i.test(value) ?
                        _markAsCorrect($field) :
                        _markAsIncorrect($field);
                    break;
                case 'tel':
                    /^\d+$/.test(value) && value.length >= 8 ?
                        _markAsCorrect($field) :
                        _markAsIncorrect($field);
                    break;
                case 'text':
                    value.length ?
                        _markAsCorrect($field) :
                        _markAsIncorrect($field);

                    break;
                case 'password':
                    value.length >= 8 ?
                        _markAsCorrect($field) :
                        _markAsIncorrect($field);

                    break;
            }

        if($field.is('textarea')) {
            value.length ?
                        _markAsCorrect($field) :
                        _markAsIncorrect($field);
        }

        if($field.is('select')) {
            value.length ?
                        _markAsCorrect($field) :
                        _markAsIncorrect($field);
        }

	}


	// -----------------------
	// Trigger DOM events

	function _markAsCorrect($field) {
		$field.removeClass('error').addClass('form-control-success');
		$field.closest('.form__container').removeClass('has-error').addClass('has-success');

		countCorrect++;

        if(!$inputs.hasClass('error')) {
            $('.error-text').fadeOut();
        }
	}

	/* function _markAsIncorrect($field) {
		$field.removeClass('form-control-success').addClass('error');
		$field.closest('.form__container').removeClass('has-success').addClass('has-error');

        $('.error-text').fadeIn();
	} */

    function sendEmail() {
        $('.error-text').fadeOut();
    }

})();

/**
 * --------------------------------------------------------------------------
 * Tabs
 * --------------------------------------------------------------------------
 */

APP.utilities.tabs = (function() {
    // --------------------------------------------------------------------------
    // Cache DOM

    var $tabs = $(".tabs__item"),
        $code = $(".tabs__code");

    // --------------------------------------------------------------------------
    // Bind events

    // --------------------------------------------------------------------------
    // Functions
    if ($(".tabs")) {
        $tabs.click(function() {
            if (!$(this).hasClass("active")) {
                var target = $(this).data("code");
                $(".tabs__item.active").removeClass("active");
                $(".tabs__code.active").removeClass("active");
                $(this).addClass("active");
                changeContent(target);
            }
        });
    }

    function changeContent(target) {
        for (var i = 0; i < $code.length; i++) {
            if ($($code[i]).data("content") === target) {
                $($code[i]).addClass("active");
            }
        }
    }
})();

/**
 * --------------------------------------------------------------------------
 * Computer
 * --------------------------------------------------------------------------
 */

APP.utilities.computer = (function() {

	// --------------------------------------------------------------------------
	// Cache DOM

		var $tabs = $('.line__column'),
				$code = $('.computer__column');

	// --------------------------------------------------------------------------
	// Bind events

	APP.utilities.pubsub.subscribe('breakpoint', setLine);
	// --------------------------------------------------------------------------
	// Functions


	function setLine() {

			$tabs.click(function() {
				if (!$(this).hasClass('active')) {
					var target = $(this).data('number'),
							picture = $(this).data('picture');
					$('.line__column.active').removeClass('active');
					$('.computer__column.active').removeClass('active');
					$(this).addClass('active');
					$('.computer__image').attr('src', picture);
					setColumn(target);
				}
			});


		function setColumn(target) {
			for (var i = 0; i < $code.length; i++) {
				if ($($code[i]).data('column') === target) {
					$($code[i]).addClass('active');
				}
			}
		}
	}



})();
/**
 * --------------------------------------------------------------------------
 * Concurrents checkbox
 * --------------------------------------------------------------------------
 */

APP.utilities.concurrent = (function() {
    if ($(".check").length > 0) {
        // --------------------------------------------------------------------------
        // Cache DOM

        // --------------------------------------------------------------------------
        // Bind events

        // --------------------------------------------------------------------------
        // Functions

        $(".check__visible").click(function() {
            if ($(".check__list").hasClass("visible")) {
                $(".check__list").removeClass("visible");
            } else {
                $(".check__list").addClass("visible");
            }
        });

        $(".check__name").click(function() {
            $(".check__concurrent").text($(this).text());
            $(".check__subtitle--concurrent").text($(this).text());
            $(".check__list").removeClass("visible");
            var number = $(this).data("check");
            _showCategories(number);
            //_getData(number);
        });
    }
    function _getData(number) {
			$.ajax({
				url: 'check.json',
				dataType: 'json',
				success: function(data) {
					$('.check__option.true').removeClass('true');
					var checks = data[number];
					var options = $('.check__option');
					$.each(checks, function(key, value) {
						if (value == 1) {
							$('.check__option:nth-child(' + key + ')').addClass('true');
						}
					});
				},
				error: function(data) { console.log('Error', data); }
			});
		}

    function _showCategories(number) {
        var categories = $('.check__categories').toArray();
        if(categories && categories.length) {
            categories.forEach(function(item){
                if(item && item.dataset && item.classList && (item.dataset['sign'] === number.toString())) {
                    item.classList.add('check__categories__visible');
                } else if (item && item.classList) {
                    item.classList.remove('check__categories__visible');
                }
            });
        }
    }

    function setVersusDefault() {
        $('.check__name--default').click();
    }

    setVersusDefault();
})();

APP.utilities.scroll = (function() {
    $('a[href*="#"]')
        .not('[href="#"]')
        .not('[href="#0"]')
				.not('[href="#buyer"]')
				.not('[href="#renters"]')
				.not('[href="#seller"]')
				.not('[href="#generalinq"]')
        .click(function(event) {
            var target = $($(this).attr("href"));
            var windowWidth = $(window).innerWidth();

            if (target.length && windowWidth <= 766) {
                event.preventDefault();
                $("html, body").animate(
                    {
                        scrollTop: target.offset().top - 49
                    },
                    1000
                );
            } else {
                event.preventDefault();
                $("html, body").animate(
                    {
                        scrollTop: target.offset().top - 96
                    },
                    1000
                );
            }
        });
})();

APP.utilities.subnav = (function() {
    var subnav = $(".subnav__list");

    function toggleVisible() {
        if ($(window).innerWidth() < 1170) {
            if (!subnav.hasClass("active")) {
                subnav.addClass("active");
            } else {
                subnav.removeClass("active");
            }
        }
	}

	function removeActive() {
		if ($(window).innerWidth() >= 1170) {
			subnav.removeClass('active');
		}
	}

    $(".subnav__features").on("click", toggleVisible);
	$(".subnav__item").on("click", toggleVisible);

	$(window).on('resize', removeActive);
})();

/*

You need to set 'display' property of
    - .msg-info
    - .msg-warning
    - .msg-error
to 'flex' in order to display specific Login Message Bar.

Example for .msg-info:
$('.msg-info').css('display', 'flex');

*/
APP.utilities.loginMsgBar = (function () {

    $('.msg-warning').css('display', 'flex');

    var $header = $('body header.header');
    var $login = $('section.login');
    var $bar = $('.login-msg-wrapper');
    $bar.each(function () {
        if ($(this).css('display') === 'flex') {
            $header.css('top', '46px');
            $login.css('padding-top', '200px');
            return false;
        }
    });
})();

APP.utilities.integrationsIsotope = (function () {

    $('.grid-integrations__grid').isotope({
        itemSelector: '.grid-element',
        layoutMode: 'fitRows'
    });

    $('.grid-integrations__filter-buttons a').click(function () {
        $('.grid-integrations__filter-buttons a').removeClass('btn--filter-active');
        $(this).addClass('btn--filter-active');

        var selector = $(this).attr('data-filter');
        $('.grid-integrations__grid').isotope({
            filter: selector
        });
        return false;
    });

})();

APP.utilities.templateGridIsotope = (function () {

    var $grid = $('.template-library-grid__content').isotope({
        itemSelector: '.template-library-grid__item',
        layoutMode: 'fitRows'
    });

    var $btn = $('.bar-subnav__link');

    var $resetButton = $('.bar-subnav__link--reset');

    // Bar subnav styles reset


    $resetButton.on('click', function (e) {
        e.preventDefault();
        $btn.each(function () {
            $(this).removeClass('bar-subnav__active');
            $(this).next('.bar-subnav__submenu').removeClass('submenu-active');
        });
    });
    $btn.on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('bar-subnav__active')) {
            $(this).removeClass('bar-subnav__active');
            $(this).next('.bar-subnav__submenu').removeClass('submenu-active');
            return;
        }
        $btn.each(function () {
            $(this).removeClass('bar-subnav__active');
            $(this).next('.bar-subnav__submenu').removeClass('submenu-active');
        });
        $(this).addClass('bar-subnav__active');
        $(this).next('.bar-subnav__submenu').addClass('submenu-active');
    });

    // Isotope click function

    var filters = {};

    $('.template-grid__filter-btn').click(function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.parents('.bar-subnav__submenu').removeClass('submenu-active');
        $btn.removeClass('bar-subnav__active');

        var $buttonGroup = $this.closest('.bar-subnav__submenu').siblings('.bar-subnav__link');
        var filterGroup = $buttonGroup.attr('data-filter-group');

        filters[filterGroup] = $this.attr('data-filter');

        var filterValue = concatValues(filters);

        if ($this.attr('data-filter') === "*") {
            filters = {};
            filterValue = "";
        }

        $grid.isotope({
            filter: filterValue
        });

        $grid.on('arrangeComplete', APP.utilities.templatesCounter.tplCount);
    });


    function concatValues(obj) {
        var value = '';
        for (var prop in obj) {
            value += obj[prop];
        }
        return value;
    }

})();

APP.utilities.templatesCounter = (function () {
    function tplCount() {

        var $amountElement = $('#templates-amount');
        var $elements = $('.template-library-grid__content > .template-library-grid__item');
        var counter = 0;

        $elements.each(function (i, val) {
            if ($(val).css('display') !== 'none') {
                counter++;
            }
        });

        $amountElement.text(counter);
    }
    $(document).ready(tplCount);

    return {
        tplCount: tplCount
    };
})();


APP.utilities.breakpoints.check();
